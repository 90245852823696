import React, { useState, useEffect } from "react";
import AccountPanel from "../../components/AccountPanel";
import * as CognitoService from "../../services/cognito.service";
import { trackAction } from "../../services/gtm.service";
import * as API from "../../config";
import jwt_decode from "jwt-decode";

const { mymaps } = API.default;

export default function Delete() {
  const [pgUserToken, setPgUserToken] = useState("");
  const [pgUserInfo, setPgUserInfo] = useState({});

  useEffect(() => {
    authPostgres();
  }, []);

  const returnUrl = () => {
    let returnUrl = "";

    if (typeof window !== "undefined") {
      if (sessionStorage.getItem("referrer"))
        returnUrl = sessionStorage.getItem("referrer");
      else returnUrl = "https://mapquest.com/";
    }

    return returnUrl;
  };

  // Authenticates with Postgres database and returns user data
  const authPostgres = async () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ token: sessionStorage.getItem("idToken") }),
      referrerPolicy: "origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(mymaps.loginUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.jwt) {
          setPgUserToken(data.jwt);
          setPgUserInfo(jwt_decode(data.jwt));
        }
      })
      .catch((error) =>
        console.log("Error caught while attempting to authenticate user", error)
      );
  };

  const deleteAccount = () => {
    // Removes data from MyMaps Postgres database
    const requestOptions = {
      method: "DELETE",
      body: JSON.stringify({ token: setPgUserToken }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + pgUserToken,
      },
    };

    fetch(mymaps.deleteUrl + pgUserInfo.id, requestOptions)
      .then((response) => {
        console.log("response.ok", response.ok);
      })
      .catch((error) =>
        console.log("Error caught while attempting to delete user", error)
      );

    // Deletes user's Cognito account
    CognitoService.deleteUser()
      .then((result) => {
        if (result === "SUCCESS") {
          CognitoService.signOut();
          window.location = returnUrl();
        }
      })
      .catch((error) => {});
  };

  return (
    <div>
      <AccountPanel />

      <div className="side-content">
        <h2>Delete Account</h2>

        <h3>Are you sure you want to delete your account?</h3>
        <p>
          We will completely remove all data relating to your account, including
          saved routes and any vehicle data.
        </p>

        <span
          className="delete success"
          role="button"
          tabIndex={0}
          onClick={() => {
            trackAction("delete_account_confirmed", "signin");
            deleteAccount();
          }}
          onKeyDown={() => {
            trackAction("delete_account_confirmed", "signin");
            deleteAccount();
          }}
        >
          Yes, delete my account
        </span>
      </div>
    </div>
  );
}
