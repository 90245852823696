export const trackAction = (action, target, args = {}) => {
  action &&
    gtag({
      action,
      target,
      event: "interaction",
      ...args,
    });
};

export const trackPageView = (page, pageType = null, options = {}) =>
  page &&
  gtag({ event: "pageview", pageType: pageType ? pageType : page, ...options });

export function gtag(args = {}) {
  if (Array.isArray(args)) return args.map(gtag);
  if (!Object.keys(args).length) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(args);
}
