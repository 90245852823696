import React, { useEffect, useState } from "react";
import { trackAction } from "../../services/gtm.service";
import * as CognitoService from "../../services/cognito.service";
import "./AccountPanel.css";

export default function AccountPanel() {
  const [firstName, setFirstName] = useState("");
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location);
      const referrer = url.searchParams.get("ref");

      if (referrer) {
        sessionStorage.setItem("referrer", referrer);
        url.searchParams.delete("ref");
        window.history.pushState(null, "", url.toString());
      }
    }

    CognitoService.isAuthenticated()
      .then((response) => {
        setIdToken(response.idToken.jwtToken);
        setFirstName(response.idToken.payload.given_name);
      })
      .catch((error) => {});

    CognitoService.getUserAttributes()
      .then((result) => {})
      .catch((error) => {});
  }, []);

  const returnUrl = () => {
    let returnUrl = "";

    if (typeof window !== "undefined" && sessionStorage.getItem("referrer")) {
      returnUrl = sessionStorage.getItem("referrer");
    } else returnUrl = "https://mapquest.com/";

    return returnUrl + "?jwtToken=" + idToken;
  };

  return (
    <div className="account-panel menu">
      <div className="sticky-header">
        <span
          className="close-nav-icon"
          onClick={() => (window.location = returnUrl())}
          aria-label="close navigation"
        >
          <i className="icon-close"></i>
        </span>
      </div>

      <div className="blocks">
        <div className="top-block">
          <div className="welcome-message">
            <div>Nice to see you,</div>
            <div className="name">{firstName}</div>
          </div>

          <a className="logout" href="/signout" role="button">
            Log out
          </a>
        </div>

        <div className="secondary-block">
          <h2>Account Settings</h2>

          <a href="/account/personalinfo">
            <span>Personal Information</span>
          </a>
          <a href="/account/security">
            <span>Account Security</span>
          </a>
          <span
            target="_self"
            className="link"
            role="presentation"
            onClick={() => {
              trackAction("delete_account_initiated", "signin");
              window.location = "/account/delete";
            }}
            onKeyDown={() => {
              trackAction("delete_account_initiated", "signin");
              window.location = "/account/delete";
            }}
          >
            <span>Delete Account</span>
          </span>
          <a
            href="https://help.mapquest.com/hc/en-us"
            target="_blank"
            rel="noreferrer"
          >
            <span>Help</span>
          </a>
        </div>
      </div>
    </div>
  );
}
